<template>
    <v-container fill-height fluid class="mx-auto">
        <v-card
            class="mx-auto mt-10"
            color="rgba(0,0,0,0)"
            flat
            :min-width="minWidth"
        >
            <v-row align="center">
                <v-col cols="12" md="6">
                    <v-img
                        :src="require('../assets/catalyst_logo.png')"
                        aspect-ratio="1"
                        contain
                        min-height="500"
                        max-height="500"
                        class="mx-5"
                        style="filter: drop-shadow(0px 0px 25px #222)"
                    ></v-img>
                </v-col>
                <v-col justify="center" cols="12" md="6">
                    <v-layout column>
                        <v-card-title class="justify-left">
                            <div class="title">
                                <h1>
                                    Excellence begins with a
                                    <vue-typer
                                        :text="['push', 'spark', 'catalyst']"
                                        :repeat="0"
                                        :shuffle="false"
                                        initial-action="typing"
                                        :pre-type-delay="200"
                                        :type-delay="50"
                                        :pre-erase-delay="600"
                                        :erase-delay="400"
                                        erase-style="select-all"
                                        :erase-on-complete="false"
                                        caret-animation="blink"
                                    ></vue-typer>
                                    <span>.</span>
                                </h1>
                            </div>
                        </v-card-title>

                        <v-card-actions class="ml-2">
                            <v-btn
                                v-for="(btn_prop, index) in button_links"
                                :key="index"
                                :color="btn_prop.color"
                                :to="btn_prop.to"
                                x-large
                                class="mt-5 white--text"
                                :href="btn_prop.url"
                                :target="btn_prop.target"
                            >
                                <v-icon>{{ btn_prop.icon }}</v-icon>
                                <span style="padding-right: 5px"></span>
                                {{ btn_prop.label }}
                            </v-btn>
                        </v-card-actions>
                    </v-layout>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                button_links: [
                    {
                        label: 'Schedule Lesson',
                        color: '#3D74FF',
                        icon: 'mdi-calendar-plus',
                        url: '',
                        to: '/schedule',
                        target: '',
                    },
                    {
                        label: 'Contact Me',
                        color: '#3F003C',
                        icon: 'mdi-account-circle',
                        // url: '',
                        to: '/about',
                        target: '',
                    },
                ],
            };
        },
        props: {
            source: String,
        },

        computed: {
            minWidth() {
                if (this.$vuetify.breakpoint.smAndDown) {
                    return '0px';
                } else {
                    return '1000px';
                }
            },
        },
        components: {},
    };
</script>

<style scoped>
    .container {
        position: absolute;
        overflow: hidden;
        z-index: 1;
        width: 100%;
        height: 100vh;
    }
    .title {
        color: white;
        font-size: 80px;
        word-break: normal;
    }
    ::v-deep .typed {
        color: #3d74ff !important;
    }
    ::v-deep .selected {
        color: #99a1ff !important;
        background-color: #555;
    }
    .sub-title {
        color: white;
        font-size: 20px;
        padding: 20px;
        word-break: normal;
    }
    .bottom-box {
        position: relative;
        bottom: 0;
        right: 0;
    }
</style>
